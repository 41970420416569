import React from 'react';

import SquareLoader from 'components/loader/SquareLoader';
import { useAuth } from 'context/auth-context';

const AuthenticatedApp = React.lazy(() => import('./Authenticated-app'));
const UnauthenticatedApp = React.lazy(() => import('./Unauthenticated-app'));

const App = (): JSX.Element => {
  const { user } = useAuth();

  return (
    <React.Suspense fallback={<SquareLoader />}>
      {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </React.Suspense>
  );
};

export default App;

import axios, { AxiosRequestConfig } from 'axios';
import Qs from 'qs';
import { useTranslation } from 'react-i18next';
import { QueryClient, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { NavigateFunction } from 'react-router-dom';

import { useAuth } from 'context/auth-context';
import { User } from 'utils/usersUtils';

const NO_SESSION = 'no session';
const SESSION_EXPIRED = 'session expired';

const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

/**
 * A utiliser que si on n'a pas accès au context AuthProvider
 */
export const request = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'NAMP-Client',
    'X-Timezone': browserTimeZone,
  },
});

function gestionErreur(
  error: unknown,
  client: QueryClient,
  setUser: (user: User | null) => void,
  navigate: NavigateFunction,
) {
  if (
    axios.isAxiosError(error) &&
    error.response?.status === 401 &&
    (error.response?.data === SESSION_EXPIRED ||
      error.response?.data === NO_SESSION)
  ) {
    client.clear();
    setUser(null);
    navigate('/');
    window.location.reload();
  }

  throw error;
}

const useAxios = () => {
  const { setUser, user } = useAuth();
  const navigate = useNavigate();
  const client = useQueryClient();
  const { i18n } = useTranslation();

  const headers = {
    'Accept-Language': i18n.resolvedLanguage,
    'X-Timezone': user ? user.getTimezone() : browserTimeZone,
  };

  const del = (url: string) => {
    return request
      .delete(url, { headers })
      .catch((error) => gestionErreur(error, client, setUser, navigate));
  };

  const get = <T>(url: string, params?: Record<string, unknown>) => {
    return request
      .get<T>(url, {
        headers,
        params,
        paramsSerializer: (p) => Qs.stringify(p, { arrayFormat: 'repeat' }),
      })
      .then((res) => res.data)
      .catch((error) => gestionErreur(error, client, setUser, navigate));
  };

  const post = (
    url: string,
    data?: Record<string, unknown>,
    config?: AxiosRequestConfig,
  ) => {
    return request
      .post(url, data, {
        headers,
        ...config,
      })
      .catch((error) => gestionErreur(error, client, setUser, navigate));
  };

  const put = (url: string, data?: Record<string, unknown>) => {
    return request.put(url, data, { headers }).catch((error) => {
      if (
        axios.isAxiosError(error) &&
        error.response?.status === 401 &&
        (error.response?.data === SESSION_EXPIRED ||
          error.response?.data === NO_SESSION)
      ) {
        client.clear();
        setUser(null);
        navigate('/');
      }
      throw error;
    });
  };

  return { del, get, post, put };
};

export default useAxios;

import { SnackbarKey, useSnackbar } from 'notistack';

import { ReactComponent as CloseIcon } from 'assets/icons/Cross.svg';
import style from 'components/snackbar/snackbarCloseButton.module.scss';

export const SnackbarCloseButton = ({ id }: { id: SnackbarKey }) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <button
      className={style.closeButton}
      data-testid="btnCloseSnackbar"
      onClick={() => closeSnackbar(id)}
    >
      <CloseIcon />
    </button>
  );
};

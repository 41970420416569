import axios, { AxiosError } from 'axios';
import qs from 'qs';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';

import { useAuth } from 'context/auth-context';
import useAxios, { request } from 'hooks/useAxios';
import { useSnackbar } from 'utils/snackbarUtils';
import {
  createUserInfos,
  User,
  UserClient,
  UserPreferences,
} from 'utils/usersUtils';

import type { Role } from 'enum/roles';

export type UserDetailResponse = {
  email: string;
  nom: string;
  prenom: string;
  role: Role;

  clients: UserClient[];
  preferences: Partial<UserPreferences> | null;
};
export function useUser(setUserInContext: (user: User) => void) {
  return useQuery({
    onSuccess: (user) => setUserInContext(createUserInfos(user)),
    queryFn: () =>
      request.get<UserDetailResponse>(`users/me`).then((res) => res.data),
    queryKey: ['user'],
  });
}

export type LoginPayload = {
  username: string;
  password: string;
};
export function useLogin() {
  const client = useQueryClient();

  return useMutation(
    (loginInfos: LoginPayload) =>
      request
        .post<string>('login', qs.stringify(loginInfos), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        })
        .catch((error: unknown) => {
          if (axios.isAxiosError(error)) {
            const errType = (error?.response?.data as string) || 'login-error';
            throw new Error(errType);
          } else {
            throw error;
          }
        }),
    {
      onSuccess: () => client.invalidateQueries('user'),
    },
  );
}

export type PreferencesPayload = Partial<{
  nbLignesTableau: number;
  timezone: string;
}>;
export function useSavePreferences() {
  const { post } = useAxios();
  const { error, success } = useSnackbar();
  const client = useQueryClient();

  return useMutation(
    (prefs: PreferencesPayload) => post('users/save-preferences', prefs),
    {
      onError: () => {
        error('my_account.preferences.error');
      },
      onSuccess: () => {
        success('my_account.preferences.success');
        void client.invalidateQueries('user');
      },
    },
  );
}

export type ModifyPasswordPayload = {
  newPassword: string;
  oldPassword: string;
};
export function useModifyPassword(resetFormulaire: () => void) {
  const { post } = useAxios();
  const { error, success } = useSnackbar();

  return useMutation(
    (data: ModifyPasswordPayload) => post('users/modify-password', data),
    {
      onError: (err) => {
        // à reprendre et faire mieux
        if (err instanceof AxiosError) {
          if (err?.response?.status === 409) {
            error('my_account.modification_mdp.error_ancien_mdp');
          } else {
            error('my_account.modification_mdp.error');
          }
        }
      },
      onSuccess: () => {
        success('my_account.modification_mdp.success');
        resetFormulaire();
      },
    },
  );
}

export function useForgotPassword() {
  return useMutation((email: string) =>
    request.post('users/forgotten-password', {
      email,
    }),
  );
}

type InitPasswordProps = {
  activationCompte: boolean;
  password: string;
  token: string;
};
function postInitPassword({
  activationCompte,
  password,
  token,
}: InitPasswordProps): Promise<void> {
  return request.post('users/init-password', {
    activationCompte,
    password,
    token,
  });
}
export function useInitPassword() {
  return useMutation((props: InitPasswordProps) => postInitPassword(props));
}

export function useLogout() {
  const { post } = useAxios();
  const { setUser } = useAuth();
  const navigate = useNavigate();

  const client = useQueryClient();
  const { closeSnackbar } = useSnackbar();

  return useMutation(() => post('logout'), {
    onSuccess: () => {
      client.clear();
      setUser(null);
      navigate('/');
      closeSnackbar();
    },
  });
}

export function useCheckUser(token: string) {
  const { get } = useAxios();
  const { status } = useQuery({
    queryFn: () => get(`users/check-link?token=${token}`),
    queryKey: ['check-link', token],
  });
  return status === 'success' ? true : false;
}

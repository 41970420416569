import React from 'react';

import { useUser } from 'api/account';
import SquareLoader from 'components/loader/SquareLoader';
import { User } from 'utils/usersUtils';

type AuthContextProps = {
  setUser: (user: User | null) => void;
  user: User | null;
};

const AuthContext = React.createContext<AuthContextProps>(
  {} as AuthContextProps,
);
AuthContext.displayName = 'AuthContext';

const AuthProvider = (props: React.PropsWithChildren) => {
  const [user, setUser] = React.useState<User | null>(null);
  const { isIdle, isLoading } = useUser(setUser);

  const value = React.useMemo(() => ({ setUser, user }), [user, setUser]);

  if (isLoading || isIdle) {
    return <SquareLoader />;
  }

  return <AuthContext.Provider value={value} {...props} />;
};

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

function useUserInfos(): User {
  const { user } = useAuth();
  if (user === null) {
    throw new Error(
      `useUserInfos doit être utilisé dans la partie authentifiée de l'application`,
    );
  }
  return user;
}

export { AuthProvider, useAuth, useUserInfos };
